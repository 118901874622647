/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, Image, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Aktuality"}>
        <Fullmap className="--style2" name={"d961yyoz2cv"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column style={{"paddingBottom":11,"marginTop":0,"paddingTop":4}} name={"nqi9pa6lu2"}>
          
          <ColumnWrap className="column__flex --center el--2 --full flex--stretch" style={{"paddingRight":3,"paddingBottom":2,"paddingTop":26}} columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":526}}>
              
              <Title className="title-box fs--26" content={"<a href=\"/\">Mateřská škola <span style=\"font-weight: bold;\">Litultovice</span></a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingLeft":0,"maxWidth":621}}>
              
              <Text className="text-box text-box--right fs--18" style={{"maxWidth":645,"paddingLeft":0,"marginBottom":1,"paddingRight":0}} content={"<span style=\"color: rgb(252, 158, 3);\">&nbsp; </span><a href=\"/projekty\" style=\"color: rgb(235, 131, 207);\">Projekty</a><span style=\"color: rgb(252, 158, 3);\">&nbsp; &nbsp; </span><a href=\"/akce-v-ms\" style=\"color: rgb(252, 158, 3);\">Akce v MŠ</a><span style=\"color: rgb(252, 158, 3);\">&nbsp; &nbsp; </span><span style=\"color: rgb(55, 116, 222);\">Pro rodiče</span><span style=\"color: rgb(252, 158, 3);\">&nbsp; &nbsp; </span><a href=\"/dokumenty\" style=\"color: rgb(253, 248, 2);\">Dokumenty</a><span style=\"color: rgb(252, 158, 3);\">&nbsp; &nbsp; </span><a href=\"/o-nas\" style=\"color: rgb(16, 156, 69);\">O nás</a><span style=\"color: rgb(252, 158, 3);\">&nbsp; &nbsp; </span><a href=\"/kontakt\" style=\"color: rgb(213, 4, 57);\">Kontakt</a><span style=\"color: rgb(252, 158, 3);\">&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1q4m1vg pt--60" style={{"marginBottom":0,"paddingBottom":139}} name={"2a1x2pyew4s"} layout={"l29"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/4c4b376f40d64f7c888b85557d7a2b5b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/4c4b376f40d64f7c888b85557d7a2b5b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/4c4b376f40d64f7c888b85557d7a2b5b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/4c4b376f40d64f7c888b85557d7a2b5b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/4c4b376f40d64f7c888b85557d7a2b5b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/4c4b376f40d64f7c888b85557d7a2b5b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/4c4b376f40d64f7c888b85557d7a2b5b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/4c4b376f40d64f7c888b85557d7a2b5b_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center flex--stretch">
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingBottom":11,"paddingTop":14}} name={"wg7sls11wdg"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":24}}>
              
              <Title className="title-box title-box--center" content={"Aktuálně v MŠ"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column layout={"l8"} style={{"paddingBottom":0,"paddingRight":66,"paddingLeft":74,"paddingTop":30}} name={"xizvm805ii"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/109a5b6477124c1bbd1f6e57cbcf4b57_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13139/109a5b6477124c1bbd1f6e57cbcf4b57_s=350x_.png 350w, https://cdn.swbpg.com/t/13139/109a5b6477124c1bbd1f6e57cbcf4b57_s=660x_.png 660w, https://cdn.swbpg.com/t/13139/109a5b6477124c1bbd1f6e57cbcf4b57_s=860x_.png 860w, https://cdn.swbpg.com/t/13139/109a5b6477124c1bbd1f6e57cbcf4b57_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13139/109a5b6477124c1bbd1f6e57cbcf4b57_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/6c40d0f2fbfa4a0c959a36af82dd17b2_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13139/6c40d0f2fbfa4a0c959a36af82dd17b2_s=350x_.png 350w, https://cdn.swbpg.com/t/13139/6c40d0f2fbfa4a0c959a36af82dd17b2_s=660x_.png 660w, https://cdn.swbpg.com/t/13139/6c40d0f2fbfa4a0c959a36af82dd17b2_s=860x_.png 860w, https://cdn.swbpg.com/t/13139/6c40d0f2fbfa4a0c959a36af82dd17b2_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="css-q8ux01 css-ns06ek pb--10 pt--10" name={"2gt2rw8a84r"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=3000x_.png);
    }
  
background-position: 50% 84%;
    `}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>

      </Layout>
    </ThemeWrapper>
  )
}